* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  height: 100%;
}
.Content {
  height: 100%;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
input {
  outline: none;
}
.flex {
  /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
  /* 老版本语法: Firefox (buggy) */
  /* 混合版本语法: IE 10 */
  /* 新版本语法: Chrome 21+ */
  display: flex!important;
}
.flex1 {
  flex: 1 1;
  flex-wrap: wrap;
}
.vTop {
  vertical-align: top;
}
.vMiddle {
  vertical-align: middle;
}
.alignCenter {
  text-align: center;
}
.red {
  color: #e92323;
}
.fb {
  font-weight: bold;
}
.fc66 {
  color: #666;
}
.f15 {
  font-size: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mr15 {
  margin-right: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mr10 {
  margin-right: 10px!important;
}
.mt10 {
  margin-top: 10px;
}
.mt5 {
  margin-top: 5px;
}
.mt20 {
  margin-top: 20px;
}
.ml15 {
  margin-left: 15px;
}
.f18 {
  font-size: 18px;
}
.LoginPage {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  width: 100%;
}
.LoginPage .content {
  height: 400px;
  text-align: center;
}
@media screen and (max-width: 980px) {
  .LoginPage .content {
    position: static;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100px;
  }
}
.LoginPage .content .productInfo {
  background: rgba(0, 0, 0, 0.4);
  width: 470px;
  height: 400px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 50px 40px;
  display: inline-block;
}
@media screen and (max-width: 980px) {
  .LoginPage .content .productInfo {
    height: 250px;
    width: 100%;
  }
}
.LoginPage .content .productInfo .title {
  font-size: 38px;
  font-family: "DongQingCuHei";
  color: #f1f6ff;
}
.LoginPage .content .productInfo .server {
  color: #aacbe6;
  font-size: 23px;
  letter-spacing: 2px;
  font-weight: lighter;
  margin: 20px 0;
}
.LoginPage .content .productInfo .line {
  width: 50px;
  height: 3px;
  background: #aacbe6;
  margin-bottom: 20px;
}
.LoginPage .content .productInfo .contentBox {
  font-size: 16px;
  color: #c9e5fe;
  width: 390px;
  line-height: 2.5;
}
.LoginPage .content .loginContent {
  background: #fff;
  height: 400px;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 25px;
  position: relative;
  margin-top: 50px;
}
@media screen and (max-width: 980px) {
  .LoginPage .content .loginContent {
    padding: 0 20px;
    width: 100%;
    height: 350px;
    margin-top: 20px;
  }
}
.LoginPage .content .loginContent .ant-select-selection {
  border: none;
}
.LoginPage .content .loginContent .label {
  color: #333;
  font-size: 20px;
  font-family: "HiraginoSansGB";
  padding-top: 10px;
  line-height: 2.5;
  text-align: left;
}
.LoginPage .content .loginContent .protocol {
  text-align: left;
  margin-top: 20px;
  font-size: 13px;
}
.LoginPage .content .loginContent .protocol input:focus {
  border-color: #f0a507 !important;
}
.LoginPage .content .loginContent .protocol .ant-checkbox {
  border-color: #f0a507 !important;
}
.LoginPage .content .loginContent .protocol .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f0a507 !important;
  border-color: #f0a507 !important;
}
.LoginPage .content .loginContent .protocol input:hover {
  border-color: #f0a507 !important;
}
.LoginPage .content .loginContent .inputBox {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
}
.LoginPage .content .loginContent .inputBox input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  color: #666666;
  font-size: 13px;
  padding-left: 35px;
}
.LoginPage .content .loginContent .inputBox .verifyCode {
  padding: 0;
  border: none;
  border-bottom: 1px solid #dadada;
  border-radius: 0;
}
.LoginPage .content .loginContent .inputBox .ant-select-selection {
  box-shadow: none!important;
}
.LoginPage .content .loginContent .inputBox .ant-select-selection input {
  box-shadow: none!important;
}
.LoginPage .content .loginContent .inputBox input {
  box-shadow: none!important;
}
.LoginPage .content .loginContent .inputBox .line {
  width: 1px;
  height: 20px;
  background: #dadada;
  align-self: center;
}
.LoginPage .content .loginContent .inputBox .phoneid {
  width: 285px;
  display: inline-block;
  padding-left: 20px;
  border: none;
}
.LoginPage .content .loginContent .inputBox img {
  position: absolute;
  left: 10px;
  top: 10px;
}
.LoginPage .content .loginContent .inputBox .smsCode {
  text-align: center;
  border: none!important;
  border-bottom: 1px solid #dadada !important;
  border-radius: 0!important;
  width: 120px;
  height: 40px;
  font-size: 13px;
  background: #fff !important;
  color: #666 !important;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
}
.LoginPage .content .loginContent .inputBox .countrys {
  width: 80px;
  display: inline-block;
  font-size: 13px;
  background: #fff;
  border-color: #ccc;
  padding-left: 5px;
  margin-right: 5px;
  border: 1px solid #ccc !important;
  position: relative;
}
.LoginPage .content .loginContent .inputBox .countrys .sanjiao {
  border-width: 5px;
  position: absolute;
  right: 10px;
  top: 14px;
}
.LoginPage .content .loginContent .inputBox .countrys .countryList {
  z-index: 9999;
  width: 170px;
  top: -1px;
  height: 350px;
}
.LoginPage .content .loginContent .inputBox:nth-child(3) {
  border: none;
}
.LoginPage .content .loginContent .loginBtn {
  color: #fff;
  font-size: 16px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  margin-top: 60px;
  border: none;
  cursor: pointer;
  height: 40px;
}
.LoginPage .content .loginContent .loginBtn a {
  text-decoration: none;
  color: #fff;
}
.LoginPage .content .loginContent .alert {
  position: absolute;
  width: 200px;
  line-height: 50px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(6, 0, 1, 0.3);
  top: 150px;
  text-align: center;
  left: 90px;
  color: #e92323;
  font-size: 15px;
}
.LoginPage .footer {
  font-size: 18px;
  color: #89bdeb;
  position: fixed;
  bottom: 45px;
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .LoginPage .footer {
    bottom: 5px;
    display: none;
  }
}
.LoginPage .footer img {
  margin-right: 15px;
}
.countrys {
  display: inline-block;
  width: 125px;
  line-height: 38px;
  vertical-align: middle;
  font-size: 14px;
  color: #999;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f4f4f4;
  border-right: none;
  position: relative;
  cursor: pointer;
  /*滚动条样式*/
}
.countrys .sanjiao {
  width: 0px;
  height: 0px;
  border-top: 6px solid #999;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  display: inline-block;
  vertical-align: middle;
  margin-top: 3px;
}
.countrys .countryList {
  background: #fff;
  border: 1px solid #eaeaea;
  width: 100%;
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: left;
  display: none;
  height: 250px;
  overflow-y: scroll;
  left: 0;
  overflow-x: hidden;
}
.countrys .countryList div {
  padding: 0 5px;
  cursor: pointer;
}
.countrys .countryList div:hover {
  background: #f4f4f4;
}
.countrys .countryList::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.countrys .countryList::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.countrys .countryList::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
#captcha {
  margin: 10px 0px;
}
.nc-container {
  background: #fff;
  width: 290px!important;
}
.nc-container .errloading {
  background: #fff;
}
.nc-container .nc_wrapper {
  width: 290px!important;
}
.nc-container .nc_scale {
  height: 40px;
}
.nc-container .nc_scale span {
  height: 38px!important;
}
.nc-container .nc-cover {
  width: 290px!important;
}
.nc-container .nc-bg {
  width: 290px!important;
}
.nc-container .nc-canvas {
  width: 290px!important;
}
.errorPage {
  min-height: 750px;
  text-align: center;
}
.ant-select-selection--single {
  height: 40px!important;
}
.ant-select-selection__rendered {
  line-height: 40px!important;
}
.ant-select ant-select-enabled {
  width: 120px!important;
}
.ant-progress-bg {
  background-color: #388ccc !important;
}
.f26 {
  font-size: 26px;
}
.f20 {
  font-size: 20px;
}
.fb {
  font-weight: bold;
}
.mb20 {
  margin-bottom: 20px;
}
.alignRight {
  text-align: right;
}
.infoPage .headStep {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
}
@media screen and (max-width: 980px) {
  .infoPage .headStep {
    width: 100%!important;
  }
}
.infoPage .headStep .navItem {
  width: 20%;
  display: inline-block;
  text-align: center;
  color: #ccc;
  cursor: pointer;
}
.infoPage .headStep .active {
  color: #000;
}
.baseInfo {
  width: 1200px;
  background: #fff;
  margin: 0 auto;
  padding: 30px;
}
@media screen and (max-width: 980px) {
  .baseInfo {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }
}
.baseInfo .infos {
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 980px) {
  .baseInfo .infos {
    width: 95%;
  }
}
.baseInfo .saveBtn {
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 40px;
}
.baseInfo .modifyBtn {
  background-color: #bbb !important;
  border-color: #bbb !important;
}
.baseInfo .infoItem {
  background: #fff;
  padding: 40px 40px;
  color: #000;
  position: relative;
  border: 2px solid #eee;
  margin-bottom: 20px;
}
@media screen and (max-width: 980px) {
  .baseInfo .infoItem {
    padding: 10px;
    padding-top: 20px;
  }
}
.baseInfo .infoItem .title {
  position: absolute;
  top: -15px;
  left: 40px;
  z-index: 99;
}
.baseInfo .infoItem .inputItem {
  display: inline-block;
  width: 50%;
  margin-bottom: 30px;
}
@media screen and (max-width: 980px) {
  .baseInfo .infoItem .inputItem {
    width: 100%;
  }
}
.baseInfo .infoItem .inputItem .label_ {
  display: inline-block;
  width: 100px;
  text-align: right;
}
.baseInfo .infoItem .w100 {
  width: 100%;
}
.baseInfo .infoItem .cover {
  width: 120px;
  position: absolute;
  height: 20px;
  background: #fff;
  top: -2px;
  left: 20px;
}
.baseInfo .infoItem input {
  width: 200px;
  line-height: 32px;
  border-radius: 4px;
}
.baseInfo .infoItem .ant-checkbox-input {
  width: auto;
}
.baseInfo .infoItem .uploadItem {
  width: 102px;
  display: inline-block;
  text-align: center;
  margin-right: 50px;
  margin-top: 30px;
}
@media screen and (max-width: 980px) {
  .baseInfo .infoItem .uploadItem {
    margin-right: 5px;
  }
}
.baseInfo .infoItem .m_table {
  display: none;
}
@media screen and (max-width: 980px) {
  .baseInfo .infoItem .m_table {
    display: block;
  }
}
.baseInfo .infoItem .costTable {
  width: 100%;
  display: table;
}
@media screen and (max-width: 980px) {
  .baseInfo .infoItem .costTable {
    display: none;
  }
}
.baseInfo .infoItem .costTable .carPrice {
  border: none;
  text-align: center;
  width: 100px;
}
.baseInfo .infoItem .costTable td,
.baseInfo .infoItem .costTable th {
  border: 1px solid #dadada;
  text-align: center;
  line-height: 45px;
  padding: 0 5px;
}
.baseInfo .infoItem .costTable .carTypeItem .hideIcon {
  display: none;
  color: #f0a507;
}
.baseInfo .infoItem .costTable .carTypeItem:hover .hideIcon {
  display: inline-block;
}
.baseInfo .tableLabel {
  border-bottom: 1px solid #eee;
  line-height: 32px;
}
.baseInfo .ant-select-selection__rendered {
  line-height: 32px!important;
}
.baseInfo .ant-select-selection--single {
  height: 32px!important;
}
.serviceInfo .label_ {
  width: 150px!important;
}
@media screen and (max-width: 980px) {
  .serviceInfo .label_ {
    width: 100px!important;
  }
}
.serviceInfo .mustIcon {
  color: red;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 5px;
}
.serviceInfo .ant-select-selection__rendered {
  line-height: 32px!important;
}
.serviceInfo .ant-select-selection--single {
  height: 32px!important;
}
.serviceInfo .ant-select-search__field {
  border: none!important;
}
.serviceInfo .citys .ant-select-selection__rendered {
  width: auto!important;
}
.serviceInfo .tableItem {
  border: 1px solid #dadada;
  padding: 10px 5px;
  position: relative;
}
.serviceInfo .tableItem:nth-child(2n) {
  border-top: none;
}
.serviceInfo .deleBtn {
  display: inline-block;
}
@media screen and (max-width: 980px) {
  .serviceInfo .deleBtn {
    display: none;
  }
}
.serviceInfo .deleBtnItem {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background: #f0a507;
  border-color: #f0a507;
  color: #fff;
}
.serviceInfo .deleBtnItem:hover,
.serviceInfo .deleBtnItem:focus {
  color: #fff !important;
  background: #f0a507;
}
.personInfo .w100 {
  display: flex!important;
  justify-content: space-around;
}
.userPage {
  width: 1200px;
  background: #fff;
  margin: 0 auto;
  padding: 30px;
}
@media screen and (max-width: 980px) {
  .userPage {
    width: 100%;
    padding: 0px;
  }
}
.userPage .inputs {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
  border: 1px solid #eee;
  padding: 0 10px;
}
@media screen and (max-width: 980px) {
  .userPage .inputs {
    width: 95%;
  }
}
.userPage .inputs .w100 {
  display: flex!important;
  justify-content: space-around;
  border-bottom: 2px solid #eee;
  padding: 20px 0px;
}
.userPage .inputs .w100 .txt {
  text-align: center;
}
@media screen and (max-width: 980px) {
  .userPage .inputs .w100 .txt {
    width: 60px!important;
  }
}
.userPage .inputs .label_ {
  display: inline-block;
  width: 150px;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .userPage .inputs .label_ {
    width: 120px!important;
  }
}
.userPage .inputs .rowItem {
  margin-top: 20px;
}
@media screen and (max-width: 980px) {
  .userPage .inputs .rowItem {
    margin-top: 10px;
  }
}
.userPage .inputs .rowItem .w50 {
  width: 50%;
  display: inline-block;
}
@media screen and (max-width: 980px) {
  .userPage .inputs .rowItem .w50 {
    width: 100%;
    margin-top: 10px;
  }
}
.userPage .inputs .rowItem img {
  width: 90px;
  height: 90px;
  margin-right: 10px;
  border: 2px solid #eee;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
}
.userPage .inputs .rowItem p img {
  margin-left: 0!important;
}
@media screen and (max-width: 980px) {
  .userPage .inputs .rowItem .long {
    width: 120px!important;
  }
}
.userPage .inputs .rowItem .w50:nth-child(1) {
  margin-top: 0;
}
.userPage .inputs .mustIcon {
  color: red;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 5px;
}
.userPage .inputs input {
  width: 200px;
  line-height: 32px;
  border: 1px solid #333;
  border-radius: 4px;
}
.userPage .ant-select-selection--single {
  line-height: 32px!important;
  height: 32px!important;
}
.userPage .ant-select-selection__rendered {
  line-height: 32px!important;
}
.userPage .ant-select-search__field {
  border: none!important;
}
.userPage .alignRight {
  border-bottom: 2px solid #eee;
  padding-bottom: 20px;
}
.userPage .saveBtn {
  line-height: 40px;
  height: 40px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
  display: inline-block;
}
.userPage table {
  width: 100%;
}
.userPage table td,
.userPage th {
  border: 1px solid #333;
  text-align: center;
  line-height: 40px;
  background: #fff;
}
.userPage .title {
  margin: 20px 0;
}
.baseInfo_first {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 0;
}
@media screen and (max-width: 980px) {
  .baseInfo_first {
    width: 100%;
  }
}
.baseInfo_first .border {
  border-radius: 4px;
}
.baseInfo_first .little {
  position: absolute;
  top: -15px;
  left: 30px;
  z-index: 99;
}
.baseInfo_first .cover {
  width: 80px;
  position: absolute;
  height: 20px;
  background: #fff;
  top: -2px;
  left: 30px;
}
.baseInfo_first .area_code {
  width: 100px;
}
.baseInfo_first .info_title {
  padding-left: 140px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .info_title {
    padding-left: 0px;
  }
}
.baseInfo_first input {
  width: 172px;
  height: 32px;
  border-radius: 4px;
  padding-left: 5px;
  line-height: 32px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first input {
    margin-top: 0!important;
  }
}
.baseInfo_first select {
  width: 172px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .ant-select,
  .baseInfo_first .ant-calendar-picker {
    margin-top: 0!important;
  }
}
.baseInfo_first .mt20 {
  margin-top: 20px;
}
.baseInfo_first .mt5 {
  margin-top: 5px;
}
.baseInfo_first .f32 {
  font-size: 32px;
}
.baseInfo_first .f22 {
  font-size: 22px;
}
.baseInfo_first .red {
  color: #e92323;
}
.baseInfo_first .f20 {
  font-size: 20px;
}
.baseInfo_first .basic_information {
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
  border: 2px solid #eee;
  padding: 10px 10px;
  position: relative;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .basic_information {
    width: 95%;
  }
}
.baseInfo_first .basic_information .infoItem {
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .basic_information .infoItem {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
.baseInfo_first .basic_information .infoItem .label_ {
  display: inline-block;
  width: 100px;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .basic_information .infoItem .label_ {
    width: 90px;
  }
}
.baseInfo_first .contact_information {
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
  border: 2px solid #eee;
  padding: 10px 10px;
  position: relative;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .contact_information {
    width: 95%;
  }
}
.baseInfo_first .contact_information .infoItem {
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .contact_information .infoItem {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
.baseInfo_first .contact_information .infoItem .label_ {
  display: inline-block;
  width: 100px;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .contact_information .infoItem .label_ {
    width: 90px;
  }
}
.baseInfo_first .next_button {
  height: 40px;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
}
.baseInfo_first .remarks {
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
  border: 2px solid #eee;
  position: relative;
  padding-left: 115px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .remarks {
    width: 95%;
    padding-left: 0;
  }
}
.baseInfo_first .remarks .remark_title {
  vertical-align: middle;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .remarks .remark_title {
    width: 90px;
    position: static;
    vertical-align: top;
    margin-top: 20px;
    display: inline-block;
    text-align: right;
  }
}
.baseInfo_first .remarks .remark_area {
  margin: 20px 0;
  width: 90%;
  height: 100px;
}
@media screen and (max-width: 980px) {
  .baseInfo_first .remarks .remark_area {
    width: 80%;
    padding-left: 0;
    margin-top: 20px;
    margin-left: 10px;
  }
}
.baseInfo_first .ant-select-selection--single {
  line-height: 32px!important;
  height: 32px!important;
}
.baseInfo_first .ant-select-selection__rendered {
  line-height: 32px!important;
}
.baseInfo_first .homeTown_ .ant-select-selection__rendered {
  width: auto!important;
}
.service_car {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  background-color: #fff;
  padding: 20px;
}
@media screen and (max-width: 980px) {
  .service_car .chooseIcon {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .service_car {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .service_car .modals {
    width: 100%;
    left: 0;
  }
}
.service_car .saveBtn {
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
}
.service_car .top_nav {
  display: flex;
}
@media screen and (max-width: 980px) {
  .service_car .top_nav .delBtn {
    display: none;
  }
}
.service_car .top_nav .reflash {
  width: 30px;
  height: 30px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  margin-left: 20px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.service_car .top_nav .addCar {
  width: 70px;
  height: 30px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  margin-left: 40px;
  text-align: right;
  padding: 0 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.service_car .top_nav .deleteCar {
  width: 70px;
  height: 30px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  margin-left: 40px;
  text-align: right;
  padding: 0 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.service_car table {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 980px) {
  .service_car table {
    width: 100%;
  }
}
.service_car table tr {
  height: 40px;
}
.service_car table .check_box {
  width: 30px;
}
.service_car .uploadItem {
  display: inline-block;
  margin-top: 20px;
}
@media screen and (max-width: 980px) {
  .service_car .uploadItem {
    display: inline-block;
    margin-top: 20px;
  }
}
.add_Car {
  margin-top: 20px;
  border: 1px solid #eee;
  top: 40%;
  left: 30%;
  z-index: 999;
  background: #fff;
  overflow: auto;
}
.add_Car .add_head {
  width: 100%;
  height: 54px;
  background-color: #0099ff;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  line-height: 54px;
  font-size: 20px;
  color: #fff;
}
.add_Car .point {
  cursor: pointer;
}
.add_Car .car_address {
  width: 100%;
  padding-left: 20px;
  margin-top: 40px;
}
@media screen and (max-width: 980px) {
  .add_Car .car_address {
    padding-left: 0px;
  }
}
@media screen and (max-width: 980px) {
  .add_Car .car_address .ant-cascader-picker {
    margin-left: 0px!important;
    margin-right: 2px;
  }
}
.add_Car .car_address input {
  line-height: 32px;
  border-radius: 4px;
  padding-left: 5px;
}
@media screen and (max-width: 980px) {
  .add_Car .car_address input {
    width: 200px!important;
  }
}
.add_Car .car_address .label_ {
  width: 100px;
  text-align: right;
  display: inline-block;
}
@media screen and (max-width: 980px) {
  .add_Car .car_address .label_ {
    width: 90px;
  }
}
.add_Car .car_address .mt10 {
  padding-left: 90px!important;
}
.add_Car .car_address .ant-select-selection__rendered {
  width: 100%!important;
}
@media screen and (max-width: 980px) {
  .add_Car .car_address .inputItem {
    margin-left: 20px;
  }
}
.add_Car .car_address .inputItem .clearfix {
  margin-left: 50px!important;
}
.add_Car .save_button {
  width: 76px;
  height: 25px;
  background-color: #0099ff;
  color: #fff;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  right: 2px;
  bottom: 2px;
}
.add_Car .ant-select-selection__rendered {
  line-height: 32px!important;
}
.add_Car .ant-select-search__field__wrap input {
  border: none;
}
.add_Car .ant-select-selection--single {
  height: 32px!important;
}
.add_Car .ant-select {
  margin-right: 10px;
}
@media screen and (max-width: 980px) {
  .add_Car .ant-select {
    width: 200px!important;
    margin-right: 0!important;
  }
}
.add_Car .ant-cascader-picker {
  margin-left: 0!important;
  margin-right: 10px;
}
.add_Car .uploadItem {
  display: inline-block;
  margin-top: 20px;
}
@media screen and (max-width: 980px) {
  .add_Car .uploadItem {
    display: inline-block;
    margin-top: 20px;
  }
}
.tipsContent {
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
  color: #ff3c00;
}
.tipsContent .iconfont {
  vertical-align: text-top;
  margin-right: 5px;
}
.tipsContent .icon-gou {
  color: #459830;
}
.ant-btn-primary a {
  color: #fff;
}
.ant-btn-primary a:hover {
  color: #fff;
}
.ant-table-thead > tr > th {
  text-align: center;
}
.ant-tabs-nav {
  width: 100%;
}
.ant-tabs-nav .ant-tabs-tab {
  width: 16.66666667%;
  text-align: center;
  margin-right: 0px!important;
}
@media screen and (max-width: 980px) {
  .ant-tabs-nav .ant-tabs-tab {
    width: 100%!important;
  }
}
.ant-tabs-tab-arrow-show {
  display: none;
}
.editor-container img {
  width: 100%;
}
@media screen and (max-width: 980px) {
  .editor-container .dialog-container .dialog {
    width: 100%!important;
    margin-left: 0px!important;
    margin-top: 0!important;
    height: auto!important;
    left: 0!important;
  }
}
@media screen and (max-width: 980px) {
  .editor-container .dialog-container .dialog .dialog-body {
    width: 100%!important;
  }
}
.exImgs {
  padding: 0 10px;
}
@media screen and (max-width: 980px) {
  .exImgs {
    padding: 0;
  }
}
.exImgs img {
  width: 100px;
  height: 100px;
  margin: 0 5px 5px 0;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  .exImgs .center {
    text-align: center;
  }
}
.exImgs .label_ {
  display: inline-block;
  width: 100px;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .exImgs .label_ {
    display: block;
    width: 90px;
    margin-bottom: 10px;
  }
}
.table_m {
  display: none;
}
@media screen and (max-width: 980px) {
  .table_m {
    display: block;
  }
}
.table_m .carItem {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.table_m .infoItem {
  display: inline-block;
  width: 50%;
}
.table_m .w100 {
  width: 100%;
}
.table_m .w100 span {
  display: inline-block;
  width: 90px;
  text-align: right;
}
.table_pc {
  display: block;
}
@media screen and (max-width: 980px) {
  .table_pc {
    display: none;
  }
}
.hiddenInput {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0;
}
.headImgHide {
  width: 100px!important;
  height: 100px;
  position: absolute;
  right: 10px;
  bottom: 0px;
  opacity: 0;
}
.ant-modal-body {
  padding-top: 30px!important;
}
.ant-modal-close-x {
  width: 30px!important;
  height: 30px!important;
  line-height: 30px!important;
}
@media screen and (max-width: 980px) {
  .ant-modal {
    position: fixed!important;
    top: 0!important;
    overflow: auto;
    bottom: 0;
    left: 8px;
    width: 100%;
  }
}
.ant-tabs-tab-active {
  border-bottom: 3px solid #1890ff !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #f0a507 !important;
  border-bottom: 3px solid #f0a507 !important;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #f0a507 !important;
}
.ant-tabs-ink-bar {
  background-color: #f0a507 !important;
}
input:focus {
  border-color: #f0a507 !important;
  box-shadow: 0 0 2px #f0a507 !important;
}
.ant-select-selection:hover {
  border-color: #f0a507 !important;
}
.ant-select-selection:focus {
  box-shadow: 0 0 2px #f0a507 !important;
}
input:hover {
  border-color: #f0a507 !important;
}
textarea:focus {
  border-color: #f0a507 !important;
}
.ant-btn-primary {
  background-color: #f0a507 !important;
  border-color: #f0a507 !important;
}
.ant-btn-primary a {
  color: #fff !important;
}
a {
  color: #f0a507 !important;
}
.welcomePage .logo {
  text-align: center;
  padding: 50px 0;
  width: 80%;
  margin: 0 auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 980px) {
  .welcomePage .logo {
    width: 100%;
    border-bottom: none;
    background: rgba(0, 0, 0, 0.03);
    padding: 30px 0;
  }
}
.welcomePage .logo img {
  width: 120px;
}
.welcomePage .logo .name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
}
@media screen and (max-width: 980px) {
  .welcomePage .logo .name {
    margin-left: 20px;
  }
}
.welcomePage .logo .name .f15 {
  letter-spacing: 3px;
  text-align: left;
}
.welcomePage .logo .name .f24 {
  font-size: 26px;
  letter-spacing: 5px;
  color: #333;
}
.welcomePage .content {
  text-align: center;
  margin-top: 100px;
}
@media screen and (max-width: 980px) {
  .welcomePage .content {
    display: none;
  }
}
.welcomePage .content a {
  width: 300px;
  line-height: 35px;
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  color: #999 !important;
  border-radius: 4px;
}
.welcomePage .content .person {
  width: 300px;
  margin-top: 30px;
  color: #f0a507 !important;
}
.welcomePage .contentM {
  display: none;
  margin-top: 50px;
  text-align: center;
}
@media screen and (max-width: 980px) {
  .welcomePage .contentM {
    display: block;
  }
}
.welcomePage .contentM .logoItem {
  width: 40%;
  display: inline-block;
  padding: 20px;
  background: rgba(0, 0, 0, 0.02);
  font-size: 16px;
  color: #999;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}
.welcomePage .contentM .logoItem img {
  width: 50px;
  margin-bottom: 10px;
}
.welcomePage .contentM .logoItem .guide {
  width: 38px;
}
.welcomePage .contentM .logoItem:nth-child(1) {
  margin-right: 20px;
}
.welcomePage .contentM .expectItem {
  width: 40%;
  display: inline-block;
}
.groupApply .basic_information {
  padding: 30px;
}
.groupApply .ant-select-selection__rendered {
  margin-left: 8px!important;
}
.groupApply .infos {
  margin-top: 30px;
}
@media screen and (max-width: 980px) {
  .groupApply .infoItem {
    padding: 0px;
    padding-top: 20px;
  }
}
@media screen and (max-width: 980px) {
  .groupApply .alignRight {
    text-align: center;
  }
}
.class-cropper-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.class-cropper-modal .modal-panel {
  width: 880px;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}
.class-cropper-modal .modal-panel .closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.class-cropper-modal .modal-panel .button-row {
  height: 45px;
  flex-shrink: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.class-cropper-modal .modal-panel .button-row .inline {
  display: inline-block;
}
.class-cropper-modal .modal-panel .button-row .submit-button {
  padding: 0 20px;
  height: 100%;
  color: #383838;
  font-size: 14px;
  background: #f0a507;
  line-height: 45px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.class-cropper-modal .modal-panel .cropper-container-container {
  flex: 1 1;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}
.class-cropper-modal .modal-panel .cropper-container-container .cropper-container {
  flex: 0 0 600px;
  margin-right: 20px;
}
.class-cropper-modal .modal-panel .cropper-container-container .cropper-container .cropper {
  width: 100%;
  height: 100%;
}
.class-cropper-modal .modal-panel .cropper-container-container .preview-container {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
}
.class-cropper-modal .modal-panel .cropper-container-container .preview-container .cropper-preview {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border: 1px solid #383838;
}
.app {
  display: flex;
  height: 100px;
  align-items: stretch;
}
.app .half-area {
  flex: 1 1;
}
.app .half-area .upload-input-label {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 8px;
  width: 100px;
  height: 100px;
  display: inline-block;
}
.app .half-area .upload-input-label .base-upload-input {
  display: none;
}
.app .half-area .img-container {
  height: 300px;
  margin-top: 24px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app .half-area .img-container .img {
  height: 100%;
}
.coverImg .upload-input-label {
  width: 120px!important;
  height: 90px!important;
}
.cropperWrap {
  display: flex!important;
  justify-content: space-around;
  width: 100%!important;
}
.pcUpload {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 980px) {
  .pcUpload {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .pcUpload {
    display: block;
  }
  .pcUpload .modal-panel {
    width: 100%;
    padding: 20px 10px;
  }
  .pcUpload .cropper-container {
    width: 100%!important;
    flex: 0 0 100%!important;
  }
  .pcUpload .closeBtn {
    right: 0px!important;
    top: 0px!important;
  }
}
.mUpload {
  display: none;
}
@media screen and (max-width: 980px) {
  .mUpload {
    display: inline-block;
  }
}
.pcShow {
  display: flex!important;
  width: 100%!important;
  justify-content: space-around;
}
@media screen and (max-width: 980px) {
  .pcShow {
    display: none!important;
  }
}
.mShow {
  display: none!important;
}
@media screen and (max-width: 980px) {
  .mShow {
    display: flex!important;
    justify-content: space-around;
  }
}
.mobileHide {
  display: inline-block !important;
}
@media screen and (max-width: 980px) {
  .mobileHide {
    display: none!important;
  }
}
.mobileShow {
  display: none!important;
}
@media screen and (max-width: 980px) {
  .mobileShow {
    position: relative;
    display: inline-block !important;
  }
}
.ant-modal-body {
  position: relative;
}
.leftIcon {
  position: absolute;
  left: 0;
  top: 200px;
  cursor: pointer;
}
.rightIcon {
  position: absolute;
  right: 0;
  top: 200px;
  cursor: pointer;
}
.pcInput {
  display: inline-block;
}
.pcInput span {
  display: none;
}
@media screen and (max-width: 980px) {
  .pcInput span {
    display: inline-block;
    width: 90px;
  }
}
@media screen and (max-width: 980px) {
  .pcInput input {
    width: 172px!important;
    margin-top: 10px!important;
  }
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  height: 84px!important;
}
.userPage .coverImg .upload-input-label {
  width: 120px!important;
  height: 90px!important;
}
.userPage .carTables {
  border: 1px dashed #dadada;
  border-top: none;
  padding: 10px;
}
.userPage .carTables .halfWidth {
  width: 50%;
  display: inline-block;
}
.userPage .carInfo .dashed {
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px dashed #dadada;
}
.app {
  position: relative;
  z-index: 99999;
}
.protocolContent {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.protocolWrap {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  text-align: left;
  color: #333;
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.protocolWrap .closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
@media screen and (max-width: 980px) {
  .protocolWrap {
    width: 80%;
    max-height: 500px;
    margin-top: 20px;
  }
}
.protocolWrap h2 {
  text-align: center;
}
.protocolWrap .title {
  margin: 20px 0;
}
.protocolWrap .color {
  color: #388ccc;
}
.protocolWrap td,
.protocolWrap th {
  border: 1px solid #dadada;
}
/*-------滚动条整体样式----*/
.protocolWrap::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/*滚动条里面小方块样式*/
.protocolWrap::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}
/*滚动条里面轨道样式*/
.protocolWrap::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
.ant-btn:hover,
.ant-btn:focus {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #f0a507 !important;
}
.alignCenter .ant-select-selection--single {
  height: 32px!important;
}
.alignCenter .ant-select-selection__rendered {
  line-height: 32px!important;
}
.alignCenter .mustIcon {
  color: red;
}
.alignCenter .label_ {
  width: 80px;
  display: inline-block;
  text-align: right;
}
@media screen and (max-width: 980px) {
  .alignCenter .label_ {
    text-align: left;
    display: block;
    width: auto;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 980px) {
  .alignCenter .inputItem {
    text-align: left;
  }
}
@media screen and (max-width: 980px) {
  .alignCenter .inputItem .ant-select {
    width: 300px!important;
  }
}
.alignCenter textarea {
  width: 300px;
  height: 100px;
}
.serviceInfo .deleBtn:hover,
.deleBtn:focus {
  color: rgba(0, 0, 0, 0.65) !important;
  background-color: #fff;
  border-color: #f0a507 !important;
}
.carPrice:hover {
  border-color: #f0a507 !important;
  border: 1px solid #f0a507 !important;
}
.elseDoc a {
  color: #000 !important;
}
.elseDoc .delBtn {
  cursor: pointer;
  color: #f0a507;
}
.elseDoc .addDoc {
  cursor: pointer;
  color: #f0a507;
  position: relative;
}
.elseDoc .addDoc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 70px;
  height: 20px;
}
.serviceState {
  cursor: pointer;
  color: #f0a507;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: none!important;
  float: initial!important;
  text-align: center!important;
}
